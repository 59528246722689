<template>
  <!--- Campos de busqueda -->
  <div class="app-container">
    <el-row class="margin-bottom-20" justify="center">
      <el-col :lg="18" :md="16" :sm="16" :xs="12">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Key" v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
       <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          @click.stop.prevent="handleSearchFilter"
          class="margin-left-10"
          size="mini"
          type="primary"
          icon="el-icon-search"
        >Buscar</el-button>
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleClean"
          size="mini"
          type="warning"
          icon="el-icon-delete"
        >Limpiar Filtros</el-button>
      </el-col>
    </el-row>
    <!---- Select Estatus ------->
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="8">
        <el-select placeholder="Tipo de Evento" v-on:change="handleSearchFilter" v-model="searchForm.type">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
         </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="8">
        <el-select placeholder="Sucursal" v-on:change="handleSearchFilter" v-model="searchForm.bio_location_key">
          <el-option
            v-for="item in biolocationList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
         </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="8">
        <el-select placeholder="Terminal" v-on:change="handleSearchFilter" v-model="searchForm.bio_terminal_key">
          <el-option
            v-for="(item, index) in bioTerminalList"
            :key="`bioTerm${index}`"
            :label="item.label"
            :value="item.value">
         </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="12" :md="12" :sm="8" :xs="24">
        <div style="text-align: center;" class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRange"
            v-on:change="handleFilterDateRegister()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
      <!---- boton export -->
      <el-col style="display: flex; justify-content: flex-start;" :md="4" :sm="8" :xs="10">
        <el-button
          class="margin-left-10"
          @click.stop.prevent="handleExportData"
          size="mini"
          type="success"
          icon="el-icon-download"
        >Exportar</el-button>
      </el-col>
      <el-col :lg="24" :md="24" :sm="16" :xs="24" style="display: flex; justify-content: flex-end">
        <!---- Paginacion -->
        <el-pagination style="margin-top: 0.5em;" @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
            :total="parseInt(totalPages)">
        </el-pagination>
      </el-col>
    </el-row>
    <!---- Tabla de Bio Subject ----->
    <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
      highlight-current-row style="width: 100%">
      <el-table-column label="Key" min-width="80" align="center">
        <template slot-scope="scope">
          <span><el-tag type="info" size="small">{{ scope.row.key }}</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column label="Tipo" min-width="60" align="center">
        <template slot-scope="scope">
          <span><el-tag :type="setTagColor(scope.row.type)" size="small">{{ scope.row.type }}</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column label="Sucursal" width="180" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.bio_location_name !==undefined ? scope.row.bio_location_name : 'N/D'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Terminal" width="180" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.bio_terminal_name !==undefined ? scope.row.bio_terminal_name : 'N/D'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Metadata" type="expand" width="110" align="center">
        <template slot-scope="scope">
          <!-- Tabla METADATA -->
          <el-table stripe :data="scope.row.metadataKeyValue" size="mini" border highlight-current-row>
            <el-table-column label="Propiedad" align="center">
              <template slot-scope="meta">
                <span style="font-weight: 800;">{{ meta.row.keyObj !== undefined ? meta.row.keyObj : 'N/D' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Valor" align="center">
              <template slot-scope="meta">
                <span>{{ meta.row.valueObj !== undefined ? meta.row.valueObj : 'N/D' }}</span>
              </template>
            </el-table-column>
          </el-table>
          <!-- Fin METADATA -->
        </template>
      </el-table-column>
      <el-table-column label="Mensaje de Evento" width="360" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.message !==undefined ? scope.row.message : 'N/D'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de Registro" width="180" align="center" fixed="right">
        <template slot-scope="scope">
          <span v-if="scope.row.created_at !==undefined">{{ scope.row.created_at | formatDateTime }}</span>
          <span v-else>{{ 'N/D' }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--- Paginacion inferior -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import { search as locationSearch } from '@/api/bioLocations.js'
import { search as terminalSearch } from '@/api/bioTerminals.js'
import { search, getPage, exportExcel } from '@/api/bioEvents.js'
const colorEvents = {
  SUCCESS: 'success',
  INFO: null,
  ERROR: 'warning'
}
export default {
  name: 'biosubject',
  data () {
    return {
      searchForm: {
        query: '',
        location: '',
        initDate: '',
        endDate: '',
        type: undefined,
        bio_terminal_key: undefined,
        bio_location_key: undefined
      },
      totalPages: '',
      currentPage: '',
      list: null,
      loading: false,
      sizePerPage: 25,
      locations: null,
      typeList: [
        {
          value: null,
          label: 'Todos los Eventos'
        },
        {
          value: 'SUCCESS',
          label: 'Success'
        },
        {
          value: 'INFO',
          label: 'Info'
        },
        {
          value: 'ERROR',
          label: 'Error'
        }],
      biolocationList: [
        {
          value: null,
          label: 'Todas las Sucursales'
        },
        {
          value: 'GRUTA',
          label: 'Gruta'
        }
      ],
      bioTerminalList: [
        {
          value: null,
          label: 'Todas las Terminales'
        }
      ],
      filterDateRegisterRange: '',
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  mounted () {
    this.getLocationSelectOptions()
    this.handleSearchFilter()
    this.getTerminalSelectOptions()
  },
  computed: {
  },
  methods: {
    setTagColor (event) {
      return colorEvents[`${event.toUpperCase()}`]
    },
    async getTerminalSelectOptions () {
      try {
        const response = await terminalSearch({ query: '' })
        if (response.success) {
          const terminalData = await response.data.data
          terminalData.forEach(terminal => {
            const cacheData = {
              value: terminal.key,
              label: terminal.name
            }
            const isDataOnTerminalList = this.bioTerminalList.some(item => (item.value === cacheData.value))
            if (!isDataOnTerminalList) {
              this.bioTerminalList.push(cacheData)
            }
          })
        }
      } catch (err) {
        this.$message.error({
          type: 'info',
          message: 'Ha ocurrido un error al obtener los datos'
        })
      }
    },
    async getLocationSelectOptions () {
      try {
        const response = await locationSearch({ query: '' })
        if (response.success) {
          const locationData = await response.data.data
          locationData.forEach(location => {
            this.biolocationList.push({
              label: location.name,
              value: location.key
            })
          })
        }
      } catch (err) {
        this.$message.error({
          type: 'info',
          message: 'Ha ocurrido un error al obtener los datos'
        })
      }
    },
    handleClean () {
      this.filterDateRegisterRange = []
      this.searchForm = {
        query: '',
        location: '',
        initDate: '',
        endDate: '',
        type: undefined,
        bio_terminal_key: undefined,
        bio_location_key: undefined
      }
      this.handleSearchFilter()
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
          console.log(this.list)
          this.list.forEach(bioObject => {
            bioObject.metadataKeyValue = []
            for (const key in bioObject.data) {
              bioObject.metadataKeyValue.push({
                keyObj: key,
                valueObj: bioObject.data[key]
              })
            }
          })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      this.searchForm.paginate = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(bioObject => {
            bioObject.metadataKeyValue = []
            for (const key in bioObject.data) {
              bioObject.metadataKeyValue.push({
                keyObj: key,
                valueObj: bioObject.data[key]
              })
            }
          })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(bioObject => {
            bioObject.metadataKeyValue = []
            for (const key in bioObject.data) {
              bioObject.metadataKeyValue.push({
                keyObj: key,
                valueObj: bioObject.data[key]
              })
            }
          })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleFilterDateRegister () {
      if (this.filterDateRegisterRange) {
        this.searchForm.initDate = this.filterDateRegisterRange[0]
        this.searchForm.endDate = this.filterDateRegisterRange[1]
        this.handleSearchFilter()
      } else {
        this.searchForm.initDate = ''
        this.searchForm.endDate = ''
        this.handleSearchFilter()
      }
    },
    async handleExportData () {
      console.log('antes de export')
      this.loading = true
      await exportExcel(this.searchForm).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'bio_event' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>
